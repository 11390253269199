<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-2 mt-2">
			<div class="input-wrap">
				<h5 class="mb-2">Alquileres</h5>
			</div>
			<div class="action-btn-wrap"></div>
		</div>
		<v-card color="secondary" class="pa-3">
			<v-row>
				<v-col cols="12" xl="6" lg="6" md="6" sm="4">
					<el-select
						v-model="search.customer_id"
						filterable
						remote
						placeholder="Cliente"
						:remote-method="searchRemoteCustomers"
						:loading="loading_search"
						loading-text="Cargando..."
						clearable
						@change="getRecords"
					>
						<el-option
							v-for="option in customers"
							:key="option.id"
							:value="option.id"
							:label="option.name"
						></el-option>
					</el-select>
				</v-col>
				<v-col cols="12" xl="3" lg="3" md="3" sm="4">
					<el-date-picker
						v-model="search.d_start"
						type="date"
						style="width: 100%;"
						placeholder="Desde"
						value-format="yyyy-MM-dd"
						format="dd/MM/yyyy"
						@change="changeDisabledDates"
					>
					</el-date-picker>
				</v-col>
				<v-col cols="12" xl="3" lg="3" md="3" sm="4">
					<el-date-picker
						v-model="search.d_end"
						type="date"
						style="width: 100%;"
						placeholder="Fecha"
						value-format="yyyy-MM-dd"
						format="dd/MM/yyyy"
						:picker-options="pickerOptionsDates"
						@change="getRecords"
					>
					</el-date-picker>
				</v-col>
			</v-row>

			<div class="row mt-2">
				<div class="col-xl-12">
					<div class="table-responsive">
						<v-simple-table dense>
							<template v-slot:default>
								<thead>
									<tr>
										<th>N°</th>
										<th>USUARIO</th>
										<th class="text-center">FECHA/HORA INGRESO</th>
										<th class="text-right">NÚMERO</th>
										<th>CLIENTE</th>
										<th class="text-center">HAB.</th>
										<th class="text-center">TIPO.</th>
										<th class="text-center">FECHA/HORA SALIDA</th>
										<th class="text-center">D</th>
										<th class="text-center">P</th>
										<th class="text-right">PRECIO</th>
										<th class="text-center">ESTADO</th>
										<th class="text-right">C.P.E</th>
										<th class="text-right">TOTAL</th>
										<th class="text-right">ACCIONES</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(row, index) in records"
										:index="customIndex(index)"
										:key="index"
									>
										<td class="align-middle">{{ index + 1 }}</td>
										<td>{{ row.user }}</td>
										<td class="text-center">{{ row.date_of_entry }}</td>
										<td class="text-right">{{ row.identifier }}</td>
										<td>{{ row.customer }}</td>

										<td class="text-center" colspan="6">
											<v-simple-table dense>
												<template v-slot:default>
												<!-- <thead>
													<tr>
													<th class="text-center">
														HAB.
													</th>
													<th class="text-center">
														TIPO.
													</th>
													<th class="text-center">
														FECHA/HORA SALIDA
													</th>
													<th class="text-center">
														D
													</th>
													<th class="text-center">
														P
													</th>
													<th class="text-right">
														PRECIO
													</th>
													</tr>
												</thead> -->
												<tbody>
													<tr
													v-for="(item) in row.items"
													:class="item.alert ? 'warning--text' : ''"
													:key="item.room"
													>
													<td>{{ item.room }}</td>
													<td>{{ item.room_type }}</td>
													<td>{{ item.date_of_exit + " " + item.time_of_exit }}</td>
													<td>{{ (row.period_id == '01') ? 0 : item.number_of_days }}</td>
													<td>{{ item.number_of_people }}</td>
													<td class="text-right">{{ item.total }}</td>
													</tr>
												</tbody>
												</template>
											</v-simple-table>
											<!-- <template v-for="(row, index) in row.items">
												<span :key="index">{{ row.room + " " }}</span>
											</template> -->
										</td>
										<td class="text-center">
											<v-chip
												v-if="row.state === 'completed'"
												class="ma-2"
												color="success"
												text-color="white"
												x-small
											>
												{{ row.state_description }}
											</v-chip> 

											<v-tooltip top>
												<template v-slot:activator="{ on }">
													<v-chip
														v-if="row.state === 'cancelled'"
														class="ma-2"
														color="error"
														text-color="white"
														dark
														x-small
														v-on="on"
													>
														{{ row.state_description }}
													</v-chip>

													
												</template>
												<span>{{ row.cancellation_reason }}</span>
											</v-tooltip>
										</td>
										<td class="text-right">
											<template v-if="row.document">
												<span v-text="row.document" class="d-block"></span>
											</template>
										</td>
										<td class="text-right">
											<strong
												>{{ row.currency_type.symbol }}
												{{ row.total }}</strong
											>
										</td>
										<td class="text-right">
											<v-tooltip top>
												<template
													v-slot:activator="{ on }"
													v-if="row.btn_generate"
												>
													<v-btn
														color="accent"
														class="my-1 mr-1"
														fab
														x-small
														dark
														v-on="on"
														@click.prevent="clickGenerate(row.id)"
													>
														<v-icon>mdi-file-document</v-icon>
													</v-btn>
												</template>
												<span>Generar comprobante</span>
											</v-tooltip>
											<v-tooltip top>
												<template
													v-slot:activator="{ on }"
												>
													<v-btn
														color="accent"
														class="my-1"
														fab
														x-small
														dark
														v-on="on"
														@click.prevent="clickPrint(row.print)"
													>
														<v-icon>mdi-printer</v-icon>
													</v-btn>
												</template>
												<span>Imprimir</span>
											</v-tooltip>
											<!-- <v-tooltip top>
												<template
													v-slot:activator="{ on }"
													v-if="
														row.state != 'pending' &&
															row.state != 'cancelled'
													"
												>
													<v-btn
														color="error"
														fab
														x-small
														dark
														class="my-1"
														v-on="on"
														@click.prevent="clickCancel(row.id)"
													>
														<v-icon>mdi-close-circle</v-icon>
													</v-btn>
												</template>
												<span>Cancelar pedido</span>
											</v-tooltip> -->
											<v-tooltip top>
												<template
													v-slot:activator="{ on }"
													v-if="
														row.consumption &&
															!row.consumption_confirmed
													"
												>
													<v-btn
														color="accent"
														fab
														x-small
														dark
														class="ml-1"
														v-on="on"
														@click.prevent="confirmConsumption(row.id)"
														v-if="row.permission"
													>
														<v-icon>mdi-check-all</v-icon>
													</v-btn>
												</template>
												<span>{{ row.concept }}</span>
											</v-tooltip>
											<v-menu offset-y>
												<template v-slot:activator="{ on, attrs }">
													<v-btn
														class="ml-1"
														color="info"
														dark
														v-bind="attrs"
														v-on="on"
														fab
														x-small
													>
														<v-icon>mdi-format-list-bulleted</v-icon>
													</v-btn>
												</template>
												<v-card dark color="secondary">
													<v-row class="mr-1 ml-1">
														<v-col cols="12" xl="12" lg="12">
															<v-simple-table dense>
																<template v-slot:default>
																	<thead>
																		<tr>
																			<th class="text-right">
																				Cantidad
																			</th>
																			<th class="text-left">
																				Producto
																			</th>
																			<th class="text-right">
																				Precio
																			</th>
																		</tr>
																	</thead>
																	<tbody>
																		<tr
																			v-for="(item,
																			index) in row.details"
																			:key="index"
																		>
																			<td>
																				{{ item.quantity }}
																			</td>
																			<td>
																				{{ item.item.name }}
																			</td>
																			<td>
																				{{
																					item.unit_price
																				}}
																			</td>
																		</tr>
																	</tbody>
																</template>
															</v-simple-table>
														</v-col>
													</v-row>
												</v-card>
											</v-menu>
										</td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>

						<div>
							<v-pagination
								@next="getRecords"
								@previous="getRecords"
								@input="getRecords"
								:length="pagination.last_page"
								:total-visible="7"
								v-model="pagination.current_page"
								circle
								prev-icon="mdi-chevron-left"
								next-icon="mdi-chevron-right"
							></v-pagination>
						</div>
					</div>
				</div>
			</div>
		</v-card>
		<generate-form
			:showDialog.sync="showDialogGenerate"
			:recordId="recordId"
			:showGenerate="true"
			:showClose="true"
		></generate-form>
	</div>
</template>

<script>
import GenerateForm from './Component/Generate';
import { confirm } from 'Mixins/confirm';
import queryString from 'query-string';
import printJS from 'print-js';
import dayjs from 'dayjs';

export default {
	components: { GenerateForm },
	mixins: [confirm],
	data() {
		return {
			resource: 'rentals',
			showDialogGenerate: false,
			loading_search: false,
			recordId: null,
			records: [],
			customers: [],
			pagination: {},
			search: {
				customer_id: null,
				d_start: dayjs()
					.startOf('month')
					.format('YYYY-MM-DD'),
				d_end: dayjs().format('YYYY-MM-DD'),
			},
			pickerOptionsDates: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM-DD');
					return this.search.d_start > time;
				},
			},
			pickerOptionsDateOfIssue: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM-DD');
					return dayjs().format('YYYY-MM-DD') < time;
				},
			},
		};
	},
	async created() {
		await this.getRecords();

		this.$eventHub.$on('reloadData', (resource) => {
			if (resource == this.resource) {
				this.getRecords();
			}
		});
	},
	methods: {
		customIndex(index) {
			return this.pagination.per_page * (this.pagination.current_page - 1) + index + 1;
		},
		getRecords() {
			this.loading = true;
			return this.$http
				.get(`/${this.resource}/records?${this.getQueryParameters()}`)
				.then((response) => {
					this.records = response.data.data;
					this.pagination = response.data.meta;
					this.pagination.per_page = parseInt(response.data.meta.per_page);

					this.loading = false;
				});
		},
		getQueryParameters() {
			return queryString.stringify({
				page: this.pagination.current_page,
				limit: this.limit,
				...this.search,
			});
		},
		clickCreate() {
			location.href = `/${this.resource}/tables`;
		},
		clickGenerate(recordId) {
			this.recordId = recordId;
			this.showDialogGenerate = true;
		},
		searchRemoteCustomers(input) {
			if (input.length > 0) {
				this.loading_search = true;
				let parameters = `input=${input}`;
				this.$http
					.get(`/documents/search/customers-only?${parameters}`)
					.then((response) => {
						this.customers = response.data;
						this.loading_search = false;
					});
			} else {
				this.customers = [];
			}
		},
		confirmConsumption(id) {
			this.$http
				.post(`/${this.resource}/confirm-consumption/${id}`)
				.then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						this.$eventHub.$emit('reloadData', this.resource);
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					this.$message.error(error.response.data.message);
				});
		},
		clickCancel(recordId) {
			this.confirm().then(() => {
				this.$http
					.post(`/${this.resource}/cancel-definitive/${recordId}`, this.form)
					.then((response) => {
						if (response.data.success) {
							this.$message.success(response.data.message);
							this.$eventHub.$emit('reloadData', this.resource);
						} else {
							this.$message.error(response.data.message);
						}
					})
					.catch((error) => {
						this.$message.error(error.response.data.message);
					});
			});
		},
		changeDisabledDates() {
			this.search.date_of_issue = null;
			if (this.search.d_end < this.search.d_start) {
				this.search.d_end = this.search.d_start;
			}

			this.getRecords();
		},
		clickPrint(url) {
			printJS(`${url}`);
		}
	},
};
</script>
